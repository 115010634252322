import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "q-pa-md",
  style: {"background-color":"#F9F9F9"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_layout, { view: "hHh LpR fff" }, {
      default: _withCtx(() => [
        _createVNode(_component_Navbar),
        _createVNode(_component_Sidebar),
        _createVNode(_component_router_view),
        _createVNode(_component_Footer)
      ]),
      _: 1
    })
  ]))
}