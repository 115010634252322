<template>
    <q-drawer
        side="right"
        v-model="sidebar"
        :width="264"
        :breakpoint="500"
        style="color:#3D3D3D"
      >
      <q-scroll-area style="height: calc(100% - 100px); margin-top: 55px; border-right: 1px solid #ddd">
          <q-list>
            <template v-for="(video, i) in videosPagador" :key="i">
              <q-item clickable v-ripple>
                <q-item-section>
                  <div class="column q-pa-sm q-gutter-xs">
                    <div class="title__video">
                      {{ video.title }}
                    </div>
                    <div class="description__video ">
                      {{ video.descripcion }}
                    </div>
                    <div style="position: relative;">
                      <q-img class="" :src="video.image" fit height="120px" style="border-radius: 10px;" /> 
                      <q-btn flat @click="getVideoDetails(video.src, video.image)" style="position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; border-radius: 10px;">
                          <q-icon name="play_circle_filled"></q-icon>
                      </q-btn>
                    </div>
                  </div>
                </q-item-section>
              </q-item>
            </template>
          </q-list>
        </q-scroll-area>
        <div class="absolute-top">
          <div class="full-height row wrap justify-between items-center">
            <div class="title__section">Tutoriales</div>
            <q-btn round flat size="12px" icon="close" @click="sidebar = false" class="q-mr-sm" style="padding: 0.5em 0.5em 0 0.5em;"/>
          </div>
          <hr>
        </div>
        
      </q-drawer>
      <q-dialog v-model="modalVideo" >
        <q-card style="width: 70vw; max-width: 80vw; border-radius: 25px; height: auto; overflow-y: hidden;">
          <Video :urlVideo="urlVideo" :posterVideo="posterVideo"/>
        </q-card>
      </q-dialog>
</template>

<script>
import { ref } from 'vue'
import Video from '@/components/Videos.vue';
export default {
    props: {
      showBar: Boolean
    },
    components: {
      Video,
    },
    setup(props){
        let sidebar = ref(props.showBar);
        let modalVideo = ref(false);
        let urlVideo = ref(null);
        let posterVideo = ref(null);

        let videosPagador = ref([
          {
            title: 'Recorrido por la plataforma',
            descripcion: 'Conoce las ventajas y formas de uso de la plataforma RedCapital',
            src: 'images/videos/Tutorial_Recorrido_por_la_plataforma.mp4',
            image: 'images/img_videos/1.1.png'
          },
        ]);

        function getVideoDetails(url, image){
          urlVideo.value = url;
          posterVideo.value = image;
          modalVideo.value = true;
        }


        return {
            sidebar, videosPagador, modalVideo, getVideoDetails, urlVideo, posterVideo,
        }
    },
    
}
</script>

<style lang="scss" scoped>
  .title__section{
    font-family: rc_font_bold;
    font-size: 24px;
    color: #3d3d3d;
    padding: 0.5em 0.5em 0 0.5em;
  }
  
  .title__video{
    font-family: rc_font_bold;
    font-size: 14px;
    color: #3d3d3d;
  }
  .description__video{
    font-family: rc_font_regular;
    font-size: 12px;
    color: #3d3d3d;
  }
</style>
