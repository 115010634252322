<template>
    <q-header>
        <q-toolbar class="navBarColor text-white" :class="{mobile: isMobile}">
                <div :class="isMobile ? 'col-5' : 'col-2'">
                    <q-img class="" src="/images/Logo_ProntoPagoB.svg" fit="contain" height="70px" />
                </div>
                
                <q-space />

                <div class="row wrap" v-if="user != null" v-show="!isMobile">
                        <div class="q-pa-md row wrap q-gutter-sm">
                            <q-btn round class="rc__btn_hover" flat @click="toggleShowSideBar" style="position: relative;">
                                <q-tooltip anchor="bottom middle" self="center middle" class="text-body">
                                    Tutoriales
                                </q-tooltip>
                                <q-icon size="md" name="help"></q-icon>
                                <div :class="$store.state.tutorial ? '' : 'rc_notificacion'" style=" "></div>
                            </q-btn>
                            <SidebarRight :showBar="isButtonPress" />
                            <q-btn-dropdown
                            flat
                            class="rc__dropdrown"
                            :label="$store.state.user.nombre"
                            @click="onMainClick"
                            >
                            <q-list>
                                <q-item clickable v-close-popup @click="onItemClick">
                                    <q-item-section>
                                        <q-avatar icon="logout" text-color="orange" />
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label>Cerrar sesi&oacute;n</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                            </q-btn-dropdown>
                        </div>
                </div>
                <div v-if="user != null" v-show="isMobile" >
                    <div class="row wrap">
                        <div class="row wrap q-gutter-sm">
                            <q-btn round class="rc__btn_hover" flat @click="toggleShowSideBar" style="position: relative;">
                                <q-icon size="sm" name="help"></q-icon>
                                <div :class="$store.state.tutorial ? '' : 'rc_notificacion'" style=" "></div>
                            </q-btn>
                            <SidebarRight :showBar="isButtonPress" />
                        </div>
                        <div class="row wrap q-gutter-sm">
                            <q-btn round class="rc__btn_hover" flat @click="menu = !menu">
                                <q-icon size="sm" name="account_circle"></q-icon>
                            </q-btn>
                        </div>
                    </div>
                    <!-- menu mobile -->
                    <q-drawer
                        side="right"
                        v-model="menu"
                        :width="264"
                        :breakpoint="500"
                        style="color:#3D3D3D"
                    >
                        <q-list>
                            <q-item clickable v-ripple>
                                <q-item-section>
                                <div class="full-width column wrap justify-center items-center content-center q-pa-md gap-2">
                                    <q-icon size="md" name="account_circle" color="orange"></q-icon>
                                    <div style="font-weight: 400; color:#FF8A00; font-size: medium; font-family: rc_font_medium;">{{ $store.state.user.name }}</div>
                                </div>
                                </q-item-section>
                            </q-item>
                            <hr>
                            <q-item clickable v-ripple v-close-popup @click="onItemClick">
                                <q-item-section>
                                    <q-avatar icon="logout" text-color="orange" />
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>Cerrar sesi&oacute;n</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-drawer>
                </div>
                <div v-else>
                    <q-btn :to="{name: 'register'}" flat label="Registrarse"/>
                    <q-btn :to="{name: 'login'}" flat label="Ingresar"/>
                </div>
        </q-toolbar>
    </q-header>
</template>

<style>
</style>

<script>
import {mapState} from 'vuex'
import store from '@/store/index'
import router from '@/router/index.ts'
import API from '@/api'
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import SidebarRight from './TutorialSideBar.vue'
export default {
    computed: mapState(['user']),
    name:'RCNavbar',
    setup () {

        let showSideBarTutorial = ref(false);
        let isMobile = ref(false);
        let menu = ref(false);

        const toggleShowSideBar= () => {
            if(!store.state.tutorial){
                store.commit("setVideoTutorial", 1);
                API.changeTutorialVideo(store.state.user.id);
            }
            showSideBarTutorial.value = !showSideBarTutorial.value;
        }

        const isButtonPress = computed(() => {
            return showSideBarTutorial
        });
         
        onBeforeMount(() => {
            showSideBarTutorial.value = false;
        });
        const onResize = () => {
			if (window.innerWidth < 960)
				isMobile.value = true;
			else
				isMobile.value = false;
		}

		onMounted(() => {
			onResize();
			window.addEventListener('resize', onResize);
		});
        
        return {
            onMainClick () {
                // console.log('Clicked on main button')
            },

            onItemClick () {
                API.logout().then(() => {
                    store.commit('logout');
                    router.push('/login');
                });

            },
            toggleShowSideBar, 
            showSideBarTutorial,
            isButtonPress,
            isMobile,
            menu
        }
    },
    components :{
        SidebarRight
    }
}
</script>

<style lang="scss" scoped>
.navBarColor{
    background: linear-gradient(90deg, #F7931E 0%, #F98A27 34%, #FE7D40 91%, #FF7545 100%);
    mix-blend-mode: normal;
}
.rc__dropdrown{
    text-transform:capitalize;
    height: auto;
    padding: 5px 17px;
    font-size:1rem !important;
    border-radius: 20px;
    border-color: white;
    border-style: solid;
    border-width: 1px;
    background: linear-gradient(90deg, #F7931E 0%, #F98A27 34%, #FE7D40 91%, #FF7545 100%);

}

.rc_notificacion {
    height: 14px; 
    width: 14px;
    position: absolute; 
    right: 0px; 
    top: 5px; 
    background-color: red; 
    border-radius: 50%;
}
:deep(.q-btn.rc__btn_hover .q-focus-helper) {
  border-radius: 50%;
}

.q-item.q-router-link--active, .q-item--active {
    background: #FF8A00 !important;
    color: white !important;
}
</style>
