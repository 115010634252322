
import {ref} from 'vue'

export default {
  name: 'newFooter',
  setup(){
    let anio = ref(new Date());
    return{
      anio
    }
  }
}

