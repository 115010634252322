
import { onBeforeMount, ref } from 'vue'
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import Sidebar from './components/Sidebar.vue'
import store from '@/store/index'
import router from '@/router/index'
import useQuasar from 'quasar/src/composables/use-quasar.js';

export default {
  name: 'LayoutDefault',

  components: {
    Navbar,
    // Login,
    Footer,
    Sidebar,
    // Business
  },

  setup () {
    const $q = useQuasar()

    onBeforeMount(() => {
      // cambio de idioma en la tablas
      $q.lang.table.allRows = 'Todo'
      setInterval(() => {
          if (store.state.user == null) {
            return;
          }
          else{
            let expireTime: Date = new Date(store.state.expireTime != null  ? store.state.expireTime  : '');
            
            if ( Date.now() > expireTime.getTime()) {
              store.commit('logout');
              router.push('/login');
  
            }
          }
        }, 5 * 1000);
    })
    return {
      leftDrawerOpen: ref(false)
    }
  }
}
