import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

export default createStore({
  plugins: [
    new VuexPersistence({
      storage: window.localStorage
    }).plugin
  ],
  state: {
    user: null,
    token: null,
    expireTime: null,
    rol: {
      id: null,
    },
    dias_restantes: null,
    elapsedTime: 0,
    intervalId: null,
    isTimerRunning: false,
    tutorial:null

  },
  getters: {
    getUser(state){return state.user},
    isTimerRunning(state) {return state.isTimerRunning}
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token){
      state.token = token;
    },
    setRolId(state, id){
      state.rol.id = id
    },
    setExpireTime(state, time){
      state.expireTime = time;
    },
    setDiasRestantes(state, dias){
      state.dias_restantes = dias;
    },
    logout(state){
      state.user = null;
      state.token = null;
      state.rol.id = null;
      state.expireTime = null;
      state.dias_restantes = null;
      state.tutorial = null;
    },
    setElapsedTime(state, elapsedTime) {
      state.elapsedTime = elapsedTime;
    },
    setIntervalId(state, intervalId) {
      state.intervalId = intervalId;
    },
    setIsTimerRunning(state, isRunning) {
      state.isTimerRunning = isRunning;
    },
    setVideoTutorial(state, payload){
      state.tutorial = payload;
    },
  },
  actions: {
    startTimer({ commit, state }) {
      if (!state.isTimerRunning) {
        const intervalId = setInterval(() => {
          commit('setElapsedTime', state.elapsedTime + 1);
          localStorage.setItem('timerState', state.elapsedTime.toString());
        }, 1000);
        commit('setIntervalId', intervalId);
        commit('setIsTimerRunning', true); // Establecer la flag en true
        localStorage.setItem('timerIntervalId', String(intervalId));
      } else {
        // Obtener el tiempo inicial del temporizador
        const initialTime = state.elapsedTime;
        // Comprobar si ha pasado un minuto desde el tiempo inicial
        const intervalId = setInterval(() => {
          commit('setElapsedTime', state.elapsedTime + 1);
          localStorage.setItem('timerState', state.elapsedTime.toString());

          if (state.elapsedTime - initialTime >= 60) {
            clearInterval(intervalId);
            commit('setElapsedTime', 0); // Reiniciar el temporizador
            localStorage.setItem('timerState', '0');
          }
        }, 1000);
      }
    },
    stopTimer({ commit, state }) {
      const intervalId = state.intervalId;
      if (intervalId) {
        clearInterval(intervalId);
        commit('setIntervalId', null);
        commit('setIsTimerRunning', false); // Establecer la flag en false
        commit('setElapsedTime', 0); // Reiniciar el temporizador
        localStorage.removeItem('timerIntervalId');
        localStorage.removeItem('timerState');
      }
    },
    loadTimerState({ commit, getters }) {
      const elapsedTime = localStorage.getItem('timerState');
      if (elapsedTime) {
        commit('setElapsedTime', parseInt(elapsedTime));
        commit('setIsTimerRunning', getters.isTimerRunning); // Establecer la flag usando el getter
      }else{
        commit('setElapsedTime', 0);
        commit('setIsTimerRunning', getters.isTimerRunning); // Establecer la flag usando el getter
      }
    }
  },
  modules: {
  }
})
